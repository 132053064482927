import React from "react";
import { FormStep } from "@helpers/ApplicationFormHooks";
import ApplicationStartSuccessPage from "@components/ApplicationStartSuccessPage/ApplicationStartSuccessPage";
import {
	useAcademiaFormState,
	ACADEMIA_FORM_STEPS,
} from "./_useAcademiaFormState";
import SEO from "@components/PageLayout/SEO";
import { PageLayoutMetaData } from "@components/PageLayout/_PageLayoutMetaData";

const AcademiaStart: React.FunctionComponent = () => {
	const { navigateToStep } = useAcademiaFormState(
		ACADEMIA_FORM_STEPS.START.formStep
	);

	return (
		<>
			<SEO title={PageLayoutMetaData.academia.title[ACADEMIA_FORM_STEPS.START.formStep]} />
			<ApplicationStartSuccessPage
				image="academia_start"
				header="Apply for Academia Pro"
				text="By submitting this form, you certify that you are staff or faculty of an accredited educational institution and that you have also agreed to our"
				includePrivacyPolicy
				buttonText="Start"
				minutes={2}
				onClick={() =>
					navigateToStep(
						(ACADEMIA_FORM_STEPS.START.formStep + 1) as FormStep
					)
				}
			/>
		</>
	);
};

export default AcademiaStart;
